<template>
  <div id="table-tabs">
    <ul>
      <li v-for="item of tabs" :key="item" :class="{active:item===curTab}" @click="curTab = item">{{ item }}</li>
    </ul>
    <passagewayRecordTab v-if="curTab==='出入口记录'"/>
    <vehiclesPresent v-if="curTab==='在场车辆'"/>
    <orderList v-if="curTab==='订单列表'"/>
  </div>
</template>

<script>
export default {
  components:{
    passagewayRecordTab:()=>import("./passagewayRecord-tab"),
    vehiclesPresent:()=>import("./vehiclesPresent-tab"),
    orderList:()=>import("./orderList-tab"),
  },
  data() {
      return {
        tabs:["出入口记录","在场车辆","订单列表"],
        curTab:"出入口记录"
      }
  },
  methods:{

  }
}
</script>

<style lang="scss">
#table-tabs {
  &>ul{
    display: flex;
    align-items: flex-end;
    border-bottom: 0.06rem solid rgba(20,34,57,0.1);
    margin-bottom: 1.09rem;
    li{
      height: 2.13rem;
      line-height: 2.13rem;
      padding: 0 1.6rem;
      font-size: 1.13rem;
      color: #eee;
      background: rgba(196,204,216,0.38);
      border-radius: 0.38rem 0.38rem 0 0;
      margin-right: 0.25rem;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
    }
    .active{
      height: 2.5rem;
      line-height: 2.5rem;
      color: white;
      background-color: rgba(0,206,193,1);
    }
  }
  .table-container{
    padding:0 !important;
    .el-pagination{
      padding-right: 0;
      .btn-next{
        margin-right: 0;
      }
    }
  }
}
</style>
